<template>
  <v-card
      class="transparent fill-height d-flex justify-center align-center flex-column"
      width="100%"
      flat
  >
    <v-card-title class="title">Page Not Found!</v-card-title>
    <v-card-subtitle class="secondary--text">
      Redirecting..
      <v-progress-linear color="info" :indeterminate="true"></v-progress-linear>
    </v-card-subtitle>
  </v-card>
</template>

<script>
export default {
  name: 'NotFound',
  layout: 'login-layout',
  data: () => ({

  }),
  created () {
    setTimeout(() => {
      this.$router.push(`/`)
    }, 2000)
  }
}
</script>

<style scoped lang="scss">

</style>
