<template>
  <v-row no-gutters class="mt-10" justify="center">
    <v-col cols="12" class="px-4">
      <h1 class="text-center">
        Using LivePerson Status Page API
      </h1>
    </v-col>
    <v-col cols="12" lg="6" class="mt-md-7 mt-3">
      <div>
        <p>Using LivePerson Status Page API will allow you to get the current state of your LivePerson services in a JSON format.</p>
        <p>This will allow you to create your own dashboard with the account status and/or connect it to your monitoring system.</p>
        <p>
          To start using our API, you will need to make a GET request with your site number to
          <a
            :href="`${host}/json?site=${$route.query.site}`"
            target="_blank"
            class="info--text"
          >
            {{host}}/json?site={{$route.query.site}}
          </a>
        </p>
        An example of an API request with account "demo":
      </div>
    </v-col>
    <v-col cols="12" class="dark-bg mt-md-7 mt-3 py-4 py-md-8">
      <v-row justify="center">
        <v-col cols="12" lg="6" class="grey-bg py-4 py-md-7">
          <pre>
            <code>
{
  visitor experience: <span class="green-text">"OK"</span>,
  login: [
      {
          incidentID: <span class="green-text">"507484"</span>,
          start_date: <span class="green-text">1476124772</span>,
          impact: <span class="green-text">"LivePerson services are unavailable. Agents connected to the Agent Console and ongoing
              chats may have disconnected."</span>,
          incident_type: <span class="green-text">"downtime"</span>
      }
  ],
  agent workspace: [
      {
          incidentID: <span class="green-text">"13213123"</span>,
          start_date: <span class="green-text">1474927200</span>,
          impact: <span class="green-text">"Visitor list is not updating in real-time. This delay may have caused the visitor list to
              temporarily show old or inaccurate visitor information. This behavior was not impacting actual
              chats or reporting data"</span>,
          incident_type: <span class="green-text">"degradation"</span>
      }
  ],
  campaign management and admin: <span class="green-text">"OK"</span>,
  reporting and bi dashboard: <span class="green-text">"OK"</span>,
  integrations and apis: <span class="green-text">"OK"</span>
  }
            </code>
          </pre>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="py-4">
      <v-row justify="center">
        <v-col cols="12" lg="6">
          <h3>Parsing the results</h3>
          <ul>
            <li>"start_date" will be in UNIX Eastern time</li>
            <li>"incident_type" will return one of the following results: downtime, suspected, degradation or 3rd party</li>
          </ul>
          <p class="mt-6 mb-0">
            For best results, we recommend that the requests will be with an interval of 2 minutes between each request.
          </p>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" lg="6" class="py-4 py-md-8">
      <h2 class="text-center">
        Maintenance API
      </h2>
      <div class="my-4">
        <p>Using LivePerson Status Page Maintenance API will allow you to get a list of on going or planned activities for your LivePerson account in a JSON format.</p>
        <p>
          To start using our Maintenance API, you will need to make a GET request with your site number to
          <a
            :href="`${host}/maintenanceapi?site=${$route.query.site}`"
            target="_blank"
            class="info--text"
          >
            {{ host }}/maintenanceapi?site={{ $route.query.site }}
          </a>
        </p>
        <p>An example of an API request with account "demo":</p>
      </div>
    </v-col>
    <v-col cols="12" class="dark-bg mt-md-7 mt-3 py-4 py-md-8">
      <v-row justify="center">
        <v-col cols="12" lg="6" class="grey-bg py-4 py-md-7">
          <pre>
            <code>
{
id: <span class="green-text">"2"</span>,
support_case: <span class="green-text">"503522"</span>,
start_date: <span class="green-text">1568124000</span>,
end_date: <span class="green-text">1568127600</span>,
service_impacted: <span class="green-text">"visitor experience,login"</span>,
impact: <span class="green-text">"During the above time frame all services will be unavailable for up to 60 minutes."</span>,
activity: <span class="green-text">"Upgrading infrastructure to improve performance and add capacity."</span>,
status: <span class="green-text"> "Visible"</span>
}
            </code>
          </pre>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="py-4">
      <v-row justify="center">
        <v-col cols="12" lg="6">
          <h3>Parsing the results</h3>
          <ul>
            <li>"start_date" will be in UNIX Eastern time</li>
            <li>"status" will return one of the following results: New or In Progress</li>
            <li>Empty array result means no scheduled maintenance planned</li>
          </ul>
          <p class="mt-6 mb-0">
            For best results, we recommend that the requests will be with an interval of 1 day between each request.
          </p>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Api',
  computed:{
    host(){
      return window.location.origin
    }
  }
}
</script>

<style scoped lang="scss">

</style>
